import React from 'react';
import Typography from '@material-ui/core/Typography';

const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      &copy; {new Date().getFullYear()} Tulus Digital Sdn Bhd.
    </Typography>
  );
};

export default Copyright;
