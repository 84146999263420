import axios, { AxiosRequestConfig } from 'axios';
import { PaymentReport, Agency } from './types';

export enum Endpoint {
  Login         = '/api/oidc/v1/token',
  PaymentReport = '/api/report/v1/payment',
  AgencyList    = '/api/report/v1/agency',
  UserInfo      = '/api/oidc/v1/userinfo',
}

export class SnapNPayReportAPI {
  private token: string;
  public constructor(userToken: string) {
    this.token = userToken;
  }

  private getBearerToken() {
    return `Bearer ${this.token}`;
  }

  public async fetchPaymentReport(
    is_download: string,
    start: string,
    end: string,
    page: number,
    items: number,
    is_approved: boolean,
    is_downloaded: boolean,
    show_charges: boolean,
    view: string,
  ): Promise<PaymentReport> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: Endpoint.PaymentReport,
      params: {
        output: is_download ? 'csv' : 'json',
        page: is_download ? '-1' : String(page),
        items: is_download ? '-1' : String(items),
        start,
        end,
        is_approved: is_approved ? '1' : '0',
        is_download: is_download ? '1' : '0',
        show_charges: show_charges ? '1' : '0',
        view: "default",
      },
      headers: { Authorization: this.getBearerToken() },
    };

    try {
      const resp = await axios(config);
      if (resp.status === 200 && resp.data) {
        return resp.data;
      }
      throw new Error('unable to fetch report');
    } catch (err) {
      throw err;
    }
  }

  public async fetchAgencyList(): Promise<Agency[]> {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: Endpoint.AgencyList,
      headers: { Authorization: this.getBearerToken() },
    };

    try {
      const resp = await axios(config);
      if (resp.status === 200 && resp.data) {
        return resp.data;
      }
      throw new Error('unable to fetch report');
    } catch (err) {
      throw err;
    }
  }
}
