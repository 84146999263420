import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Copyright from '../components/Copyright';
import FPXRequests from './FPXRequests';
import { useStore, logout } from '../Store';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  dev: {
    backgroundColor: '#ffd',
    display: 'flex',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  accountMenu: {
    color: '#eee',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const { state, dispatch } = useStore();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let isDev = false;
  let initView = "default";
  if (state.featureflags !== undefined) {
    initView = state.featureflags!.dashboard!.view as string;
    if (state.featureflags.dev !== undefined && state.featureflags.dev === true) {
      isDev = true;
    }
  }

  let avatarUrl = "";

  let accountType = "v1";
  if (state.info && state.info.account_type && state.info.account_type === 'identity') {
    accountType = "v2";
    if (state.info.image) {
      avatarUrl = state.info.image;
    }
  } else {
    if (state.info && state.info.image) {
      avatarUrl = 'https://assets.tulus.my/images/' + state.info.image;
    }
  }

  

  return (
    <div className={ isDev? classes.dev : classes.root }>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Tulus Merchant Dashboard { isDev ? "(Dev)" : "" }
          </Typography>
          <div>
            <Button
              aria-controls="account-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.accountMenu}
            >
              {state.info && state.info.image && (
                <Avatar
                  alt={state.info.username}
                  src={
                    avatarUrl
                  }
                  style={{ marginRight: 10 }}
                />
              )}
              Account
              {state.info && state.info.username && (
                <span>({state.info.username})</span>
              )}
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              id="account-menu"
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Logout
              </MenuItem>
              {state.featureflags && (
              <MenuItem>
                <CopyToClipboard text={state.token}>
                  <span>Copy AuthToken to Clipboard</span>
                </CopyToClipboard>
              </MenuItem>
              )}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListSubheader inset>Reports</ListSubheader>
          <ListItem button>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Requests" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {state.info && state.info.username && state.token && (
            <FPXRequests username={state.info.username} token={state.token} initView={initView} accountType={accountType} />
          )}
        </Container>

        <Container maxWidth="lg" className={classes.container}>
          <Copyright />
        </Container>
      </main>
    </div>
  );
};

export default Dashboard;
