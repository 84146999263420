import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, FPXZakatData, MAIKData } from '../../types';
// import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },

  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const MAIKReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  // const { state } = useStore();
/*
Tarikh Teks/Laporan	31
Keterangan Tambahan
Tarikh Transaksi	62
No.IC	18
Nama Pembayar	42
Amaun Bayaran	11
Nilai Fitrah
No.Ruj Pembayaran	14
Jenis Zakat	20
Saluran	5
E-mel	100
Alamat Pembayar 1*	42
No.Telefon	21
Poskod*	5
Daerah/Bandar*	37
Negeri*	42
*/
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        maik: {} as MAIKData,
      } as ExtraMap;
    }

    if (data2[i]!.extra!.maik === undefined) {
      data2[i]!.extra!.maik = {
        tarikh_laporan: ""
      } as MAIKData;
    }
  }
  
  let titleCase = (str?: string) => {
    if (str === undefined) {
      return "";
    }
    return str.replaceAll("_", " ").toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }

  return (
    <React.Fragment>
      <Title>MAIK Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Nama Pembayar</TableCell>
            <TableCell>No Kad Pengenalan</TableCell>
            <TableCell>No Telefon</TableCell>
            <TableCell>Alamat</TableCell>
            <TableCell>Email Pembayar</TableCell>
            <TableCell>Jenis Zakat</TableCell>
            <TableCell>Bil Tanggungan</TableCell>
            <TableCell>Kadar Fitrah</TableCell>
            <TableCell>Jumlah Keseluruhan Bayaran</TableCell>
            <TableCell>Nombor Rujukan/Seller Order number</TableCell>
            <TableCell>Tarikh Bayaran Zakat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map(item => (
            <TableRow key={item.fpx_seller_txn_time}>
              <TableCell>{item.extra?.maik?.nama_pembayar}</TableCell>
              <TableCell>{item.extra?.zakat?.zakat_ic_no}</TableCell>
              <TableCell>{item.extra?.maik?.no_telefon}</TableCell>
              <TableCell>{item.extra?.maik?.alamat}</TableCell>
              <TableCell>{item.extra?.maik?.emel}</TableCell>
              <TableCell>{titleCase(item.extra?.zakat?.zakat_type)}</TableCell>
              <TableCell>{item.extra?.zakat?.zakat_dependant}</TableCell>
              <TableCell>{item.extra?.zakat?.zakat_rate}</TableCell>
              <TableCell align="right">{item.extra?.zakat?.zakat_amount}</TableCell>
              <TableCell>{item.extra?.maik?.ruj_pembayaran}</TableCell>
              <TableCell>{item.extra?.maik?.tarikh_transaksi}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default MAIKReport;
