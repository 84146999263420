import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, FPXZakatData, MAIWPData } from '../../types';
// import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
    pre: {
      fontFamily: "monospace",
    }

  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const MAIWPReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        maiwp: {} as MAIWPData,
      } as ExtraMap;
    }  
      
    if (data2[i]!.extra!.maiwp === undefined) {
      data2[i]!.extra!.maiwp = {
      } as MAIWPData;
    }
 // extra!.zakat!.zakat_dependant
    if (data2[i]!.extra!.zakat === undefined) {
      data2[i]!.extra!.zakat = {
      } as FPXZakatData;
    } 
    if (data2[i]!.extra!.zakat!.zakat_dependant < 1) {
      data2[i]!.extra!.zakat!.zakat_dependant = 1;
    }
  }

  return (
    <React.Fragment>
      <Title>MAIWP Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Order ID</TableCell>
            <TableCell>Merchant Name</TableCell>
            <TableCell>Zakat Code</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell>Quantity (Dependents)</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Commission</TableCell>
            <TableCell>Nett Total</TableCell>
            <TableCell>IC Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Detailed Address</TableCell>
            <TableCell>Postcode</TableCell>
            <TableCell>Area</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Year of Zakat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map(item => (
            <TableRow key={item.fpx_seller_txn_time}>
              <TableCell>{item.extra!.maiwp!.tarikh_transaksi}</TableCell>
              <TableCell>{item.fpx_seller_order_no}</TableCell>
              <TableCell>Tulus</TableCell>
              <TableCell>{item.extra!.maiwp!.zakat_code}</TableCell>
              <TableCell>{item.extra!.maiwp!.jenis_zakat}</TableCell>
              <TableCell align="right">{item.extra!.zakat!.zakat_dependant}</TableCell>
              <TableCell align="right">{item.amount}</TableCell>
              <TableCell align="right">{item.extra!.maiwp!.amount_commission}</TableCell>
              <TableCell align="right">{item.extra!.maiwp!.amount_net}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_ic_no}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_name}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_email}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_phone}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_address}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_postcode}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_zone}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_state}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_haul}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default MAIWPReport;
