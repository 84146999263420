import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, FPXZakatData, MAIJData } from '../../types';
// import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },

  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const MAIJReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  // const { state } = useStore();
/*
Tarikh Teks/Laporan	31
Keterangan Tambahan
Tarikh Transaksi	62
No.IC	18
Nama Pembayar	42
Amaun Bayaran	11
Nilai Fitrah
No.Ruj Pembayaran	14
Jenis Zakat	20
Saluran	5
E-mel	100
Alamat Pembayar 1*	42
No.Telefon	21
Poskod*	5
Daerah/Bandar*	37
Negeri*	42
*/
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        maij: {} as MAIJData,
      } as ExtraMap;
    }  
      
    if (data2[i]!.extra!.maij === undefined) {
      data2[i]!.extra!.maij = {
        tarikh_laporan: ""
      } as MAIJData;
    }
    if (data2[i]!.extra!.zakat === undefined) {
      data2[i]!.extra!.zakat = {
      } as FPXZakatData;
    }
  }

  return (
    <React.Fragment>
      <Title>MAIJ Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Tarikh Teks/Laporan</TableCell>
            <TableCell>Keterangan Tambahan</TableCell>
            <TableCell>Tarikh Transaksi</TableCell>
            <TableCell>No.IC</TableCell>
            <TableCell>Nama Pembayar</TableCell>
            <TableCell>Amaun Bayaran</TableCell>
            <TableCell>Nilai Zakat</TableCell>
            <TableCell>No.Ruj Pembayaran</TableCell>
            <TableCell>Jenis Zakat</TableCell>
            <TableCell>Saluran</TableCell>
            <TableCell>E-mel</TableCell>
            <TableCell>Alamat Pembayar 1</TableCell>
            <TableCell>Alamat Pembayar 2</TableCell>
            <TableCell>Alamat Pembayar 3</TableCell>
            <TableCell>No.Telefon</TableCell>
            <TableCell>Poskod</TableCell>
            <TableCell>Daerah/Bandar</TableCell>
            <TableCell>Negeri</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map(item => (
            <TableRow key={item.fpx_seller_txn_time}>
              <TableCell>{item.extra!.maij!.tarikh_laporan}</TableCell>
              <TableCell></TableCell>
              <TableCell>{item.extra!.maij!.tarikh_transaksi}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_ic_no}</TableCell>
              <TableCell>{item.extra!.maij!.nama_pembayar}</TableCell>
              <TableCell align="right">{item.extra!.zakat!.fpx_txn_amount}</TableCell>
              <TableCell align="right">{item.extra!.zakat!.zakat_amount}</TableCell>
              <TableCell>{item.extra!.maij!.ruj_pembayaran}</TableCell>
              <TableCell>{item.extra!.maij!.jenis_zakat}</TableCell>
              <TableCell>SNP</TableCell>
              <TableCell>{item.extra!.maij!.emel}</TableCell>
              <TableCell>{item.extra!.maij!.alamat1}</TableCell>
              <TableCell>{item.extra!.maij!.alamat2}</TableCell>
              <TableCell>{item.extra!.maij!.alamat3}</TableCell>
              <TableCell>{item.extra!.maij!.no_telefon}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_postcode}</TableCell>
              <TableCell>{item.extra!.maij!.bandar}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default MAIJReport;
