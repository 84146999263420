import React, { useEffect, useCallback } from "react";
import Login from "./views/Login";
// import LoginBox from "./components/LoginBox";
import Dashboard from "./views/Dashboard";
// import Developer from "./views/Developer";
import {
  useStore,
  login,
  logout,
  updateInfo,
  updateFeatureflags,
} from "./Store";
import { Endpoint } from "./common";
import axios, { AxiosRequestConfig } from "axios";
// import {
//   HashRouter as Router,
//   Route,
//   Switch,
//   Redirect,
// } from "react-router-dom";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { Provider } from "react-redux";
// import configureStore from "./store/index";
import qs from "querystring";

import firebase from "firebase";
import "firebase/auth";
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth";

import { firebaseConfig } from "./firebaseconfig";

// const { store } = configureStore();

let loginfirebase = false; // A debouncer to help not trigger things when things are happening async-ly.

const App = ({
  signInWithGoogle,
  signInWithEmailAndPassword,
  signOut,
  user,
}: WrappedComponentProps) => {
  const { state, dispatch } = useStore();
  const { token } = state;

  const postLogin = useCallback(async(token: string) => {
    dispatch(login({ token }));
  }, [dispatch]);

  useEffect(() => {
    // console.log(user);
    if (!user) {
      return;
    }

    if (user != null && !token) {
      // console.log("ran");
      loginfirebase = true;
      user.getIdToken().then((firebasetoken) => {
        // console.log(firebasetoken);
        const config: AxiosRequestConfig = {
          method: "post",
          url: Endpoint.Login,
          data: qs.stringify({ firebasetoken: firebasetoken }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        };
        axios(config).then((resp) => {
          if (resp.status !== 200) {
            dispatch(logout());
            return;
          }
          if (resp.status === 200) {
            // alert("signedin");
            postLogin(resp.data.access_token);
          }
        });
      });
    }
  }, [user, dispatch, postLogin, token]);

  useEffect(() => {
    // console.log(token);
    (async () => {
      if (user != null && token === "" && loginfirebase) {
        signOut();
        // user = null; // Assignments to the 'user' variable from inside React Hook useEffect will be lost after each render
      }

      if (!token) {
        return;
      }

      // user_info
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: Endpoint.UserInfo,
          headers: { Authorization: `Bearer ${token}` },
        };
        const resp = await axios(config);
        if (resp.status !== 200) {
          dispatch(logout());
          return;
        }
        loginfirebase = true;
        dispatch(updateInfo(resp.data));
      } catch (e) {
        dispatch(logout());
      }

      // featureflag
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: "/featureflags.json",
          headers: { Authorization: `Bearer ${token}` },
        };
        const resp = await axios(config);
        if (resp.status !== 200) {
          return;
        }
        dispatch(updateFeatureflags(resp.data));
      } catch (e) {
        // dispatch(logout());
      }
    })();
  }, [token, dispatch, signOut, user]);

  let signInWithGoogle2 = function () {
    loginfirebase = false;
    signInWithGoogle();
  };

  const signInWithPassword = (email: string, password: string) => {
    // createUserWithEmailAndPassword(email, password);
    signInWithEmailAndPassword(email, password);
  };

  if (state.token && state.info) {
    return <Dashboard />;
  }
  return (
    <Login
      setToken={postLogin}
      signInWithPassword={signInWithPassword}
      signInWithGoogle={signInWithGoogle2}
    />
  );

};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
