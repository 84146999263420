import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import qs from "querystring";
import backgroundImage from "../assets/bg-computer.jpg";

import Copyright from "../components/Copyright";
import { Endpoint } from "../common";

import firebase from "firebase";
import "firebase/auth";

import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd,
} from "@react-firebase/auth";
import { firebaseConfig } from "../firebaseconfig";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props {
  setToken: (token: string) => void;
  signInWithGoogle: () => void;
  signInWithPassword: (email: string, password: string) => void;
}

interface State {
  username: string;
  password: string;
}

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const firebaseAppAuth = firebaseApp.auth();
// const providers = {
//   googleProvider: new firebase.auth.GoogleAuthProvider(),
// };

const Login: React.FC<Props> = ({
  setToken,
  signInWithGoogle,
  signInWithPassword,
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState<State>({
    username: "",
    password: "",
  });

  let isDev = false;

  // FIXME: This is a hack to get around the fact we don't have feature flags loaded yet.
  if (document.location.hostname.includes("dev") || document.location.hostname.includes("localhost")) {
    isDev = true;
  }

  const handleChange = (name: keyof State) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({ ...state, [name]: event.target.value });
  };

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // poor man email check
    // TODO fix this
    if (state.username && state.username.includes("@")) {
      signInWithPassword(state.username, state.password);
    } else {
      try {
        const config: AxiosRequestConfig = {
          method: "post",
          url: Endpoint.Login,
          data: qs.stringify({ ...state, grant_type: "password" }),
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        };

        const resp = await axios(config);
        if (resp.status === 200) {
          setToken(resp.data.access_token);
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  let isFlag = state.username === "administrator";

  let FirebaseAuthSection;
  if (isFlag) {
    FirebaseAuthSection = (
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
        <div>
          <Grid container spacing={2} justify="space-around">
            <Grid item xs={4} sm={4} md={4}>
              <Button
                color="primary"
                className={classes.submit}
                type="submit"
                variant="contained"
                onClick={() => {
                  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                  firebase.auth().signInWithPopup(googleAuthProvider);
                }}
              >
                Sign In with Google
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Button
                color="primary"
                variant="contained"
                className={classes.submit}
                data-testid="signin-anon"
                onClick={() => {
                  firebase.auth().signInAnonymously();
                }}
              >
                Sign In Anonymously
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Button
                color="primary"
                variant="contained"
                className={classes.submit}
                onClick={() => {
                  firebase.auth().signOut();
                }}
              >
                Sign Out
              </Button>
            </Grid>
          </Grid>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return (
                <pre style={{ height: 300, overflow: "auto" }}>
                  {JSON.stringify({ isSignedIn, user, providerId }, null, 2)}
                </pre>
              );
            }}
          </FirebaseAuthConsumer>
          <div>
            <IfFirebaseAuthed>
              {() => {
                return <div>You are authenticated</div>;
              }}
            </IfFirebaseAuthed>
            <IfFirebaseAuthedAnd
              filter={({ providerId }) => providerId !== "anonymous"}
            >
              {({ providerId }) => {
                return <div>You are authenticated with {providerId}</div>;
              }}
            </IfFirebaseAuthedAnd>
          </div>
        </div>
      </FirebaseAuthProvider>
    );
  } else {
    FirebaseAuthSection = <React.Fragment />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={isDev ? {background:"#ffd"}:{}}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={login}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="User"
              name="username"
              autoComplete="username"
              autoFocus
              value={state.username}
              onChange={handleChange("username")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={state.password}
              onChange={handleChange("password")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={signInWithGoogle}
            >
              Sign In With Google
            </Button>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>

        {FirebaseAuthSection}
      </Grid>
    </Grid>
  );
};

export default Login;
