// SnapNPay & Tulus Data Dictionary
// See also https://docs.google.com/spreadsheets/d/1XOyJUvp_wJepbN_deQd7gUjN5CsDpA9uH8bSriheNc8/edit#gid=0

interface DataDictionary {
    code: string;
    name: string
}

// PAYMENT-METHOD in data dictionary
export const PaymentMethod : Map<string, DataDictionary> = new Map([
    ["fpx.b2c", {code: "fpx.b2c", name: "FPX B2C" }],
    ["fpx.b2b1", {code: "fpx.b2b1", name: "FPX B2B" }],
    ["fpx.b2b2", {code: "fpx.b2b2", name: "FPX B2B" }],
    ["mpgs", {code: "mpgs", name: "Credit/Debit Card (MPGS)" }],
]);

// Sync with FPXRequestList.tsx
export const reportviews : { code: string; name: string }[] = [
    {
        code: "default",
        name: "Default",
    },
    {
        code: "MAIJ",
        name: "MAIJ Report",
    },
    {
        code: "MAIPS",
        name: "MAIPs Report",
    },
    {
        code: "MAIK",
        name: "MAIK Report",
    },
    {
        code: "ZAKATSTAT",
        name: "Statistik Zakat",
    },
    {
        code: "MAIDAM",
        name: "MAIDAM Report",
    },
    {
        code: "MAIAMP",
        name: "MAIAMP Report",
    },
    {
        code: "MAIWP",
        name: "MAIWP Report",
    },
    {
        code: "UNHCR",
        name: "UNHCR Report",
    },
    {
        code: "IKHLAS",
        name: "Ikhlas Report",
    },
    {
        code: "PIJ",
        name: "PIJ Report",
    },
];
