import React from "react";
import { FPXRequestReport } from "../types";
import MAIJReport from "./report/MAIJReport";
import MAIPSReport from "./report/MAIPSReport";
import MAIKReport from "./report/MAIKReport";
import ZakatStatReport from "./report/ZakatStatReport";
import MAIDAMReport from "./report/MAIDAMReport";
import MAIAMPReport from "./report/MAIAMPReport";
import IkhlasReport from "./report/IkhlasReport";

import DefaultReport from "./report/DefaultReport";
import MAIWPReport from "./report/MAIWPReport";
import UNHCRReport from "./report/UNHCRReport";

interface Props {
  data: FPXRequestReport[];
  view: string;
  onClick?: (order_id: string) => void;
}

// Please sync with zakat_data.go and datadictionary.ts
const FPXRequestList: React.FC<Props> = ({ data, view }) => {
  switch (view) {
    case "MAIJ":
      return <MAIJReport data={data}></MAIJReport>;
    case "MAIPS":
      return <MAIPSReport data={data}></MAIPSReport>;
    case "MAIK":
      return <MAIKReport data={data}></MAIKReport>;
    case "ZAKATSTAT":
      return <ZakatStatReport data={data}></ZakatStatReport>;
    case "MAIDAM":
      return <MAIDAMReport title="MAIDAM Report Requests" data={data}></MAIDAMReport>;
    case "MAIAMP":
      return <MAIAMPReport data={data}></MAIAMPReport>;
    case "IKHLAS":
      return <IkhlasReport data={data}></IkhlasReport>;
    case "MAIWP":
      return <MAIWPReport data={data}></MAIWPReport>;
    case "UNHCR":
      return <UNHCRReport data={data}></UNHCRReport>;
    case "PIJ":
      return <MAIDAMReport title="PIJ Report Requests" data={data}></MAIDAMReport>;
    default:
      return <DefaultReport data={data}></DefaultReport>;
  }
};

export default FPXRequestList;
