import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useStore } from '../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  })
);
interface Props {
  page: number;
  items_per_page: number;
  count: number;
  total_pages: number;
  txn_amount: number;
  txn_charges: number;
  txn_summary: {
    name: string;
    count: number;
    txn_amount: number;
    txn_charges: number;
  }[];
}

const FPXSummary: React.FC<Props> = props => {
  const classes = useStyles();
  const { state } = useStore();

  let showCharges = false;
  // I hate (this version of) TypeScript.
  if (state && state!.featureflags && state!.featureflags!.dashboard !== undefined && state!.featureflags!.dashboard!.showCharges !== undefined) {
    showCharges = state!.featureflags!.dashboard.showCharges;
  }

  if (showCharges) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              Transaction Count
            </Typography>
            <Typography component="p">{props.count.toLocaleString('en')}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              Total Gross Amount
            </Typography>
            <Typography component="p">
              RM {props.txn_amount.toLocaleString('en',{minimumFractionDigits:2,maximumFractionDigits:2})}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              Total Charges
            </Typography>
            <Typography component="p">
              RM {props.txn_charges.toLocaleString('en',{minimumFractionDigits:2,maximumFractionDigits:2})}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              Transaction Count
            </Typography>
            <Typography component="p">{props.count.toLocaleString()}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h2">
              Total Amount
            </Typography>
            <Typography component="p">
              RM {props.txn_amount.toLocaleString('en',{minimumFractionDigits:2,maximumFractionDigits:2})}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
};

export default FPXSummary;
