import React, { useState } from 'react';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

interface Props {
  onClick: (startDate: string | null, endDate: string | null) => void;
  start: Date;
  end: Date;
}

type DateTypes = 'startDate' | 'endDate' | null;
type InputDate = moment.Moment | null;

interface Input {
  startDate: InputDate;
  endDate: InputDate;
}

const DatePicker: React.FC<Props> = ({ onClick, start, end }) => {
  const [startDate, setStartDate] = useState<InputDate>(moment(start));
  const [endDate, setEndDate] = useState<InputDate>(moment(end));
  const [focusedInput, setFocusedInput] = useState<DateTypes>(null);

  const onDatesChange = (args: Input) => {
    const { startDate, endDate } = args;
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate !== null && endDate !== null) {
      onClick(startDate.format('YYYY/MM/DD'), endDate.format('YYYY/MM/DD'));
    }
  };

  return (
    <DateRangePicker
      numberOfMonths={1}
      minimumNights={0}
      displayFormat={() => "DD/MM/YYYY"}
      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
      startDateId="startDate"
      endDateId="endDate"
      startDate={startDate}
      endDate={endDate}
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
    />
  );
};

export default DatePicker;
